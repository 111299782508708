<template>
  <div class="login_container">
    <!-- 登录盒子  -->
    <div class="login_box">
      <!-- 头像 -->
      <!--<div class="avatar_box">
       <img src="../assets/logo.png" alt>
      </div>-->
      <div class="title">欢迎使用智慧分析</div>
      <!-- 登录表单 -->
      <el-form :model="loginForm" ref="loginForm" :rules="rules" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="name">
          <el-input @keyup.enter="login('loginForm')" v-model="loginForm.name" prefix-icon="el-icon-user"
            placeholder="请输入用户名"></el-input>
        </el-form-item>
        <!-- 手机号码 -->
        <el-form-item prop="phone">
          <el-input @keyup.enter="login('loginForm')" v-model="loginForm.phone" prefix-icon="el-icon-phone"
            placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password" style="margin-bottom:15px">
          <el-input @keyup.enter="login('loginForm')" type="password" v-model="loginForm.password"
            prefix-icon="el-icon-lock" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <!-- <el-checkbox v-model="checked" style="font-size: 12px; color: #888;">记住我</el-checkbox> -->
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login('loginForm')">登录</el-button>
          <el-button type="info" @click="resetLoginForm('loginForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="link">
        <router-link class="forget" to="/">忘记密码？</router-link>
        <router-link class="register" to="/register">注册账号</router-link>
      </div>
    </div>
    <div class="footer">
      <div class="copy">版权所有Copyright &copy智慧分析</div>
      <div><a
            style="text-decoration: none;color: white;"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >已备案:粤ICP备2021054626号</a
          ></div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      const validatePhone = (rule, value, callback) => {
        const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (value === "") {
          callback(new Error("请输入手机号码"));
        } else {
          if (!reg.test(value) && value !== "") {
            callback(new Error("请输入正确的手机号码"));
          } else {
            callback();
          }
        }
      };
      const validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          callback();
        }
      };
      return {
        checked: true,
        // 数据绑定
        loginForm: {
          neme: "",
          phone: "",
          password: ""
        },
        // 表单验证规则
        rules: {
          phone: [{
            validator: validatePhone,
            trigger: 'blur'
          }],
          password: [{
            validator: validatePass,
            trigger: 'blur'
          }]
        }
      };
    },
    methods: {
      resetLoginForm(form) {
        // this=>当前组件对象，其中的属性$refs包含了设置的表单ref
        this.$refs[form].resetFields();
      },
      login(form) {
        this.$refs[form].validate(async valid => {
          if (!valid) {
            return;
          }
          // 发送登录请求
          const {
            data: res
          } = await this.$http.get("user/login", {
            params: {
              name: this.loginForm.name,
              phone: this.loginForm.phone,
              password: this.$md5(this.loginForm.password),
              autoLogin: this.checked,
              device: "PC"
            }
          });

          if (res.code != 200) {
            return this.$message.error(res.msg);
          }
          if (res.code == 200) {
            this.$message.success("登录成功");
            if (this.checked) {
              this.$store.commit("$_setLocalToken", res.data.tokenValue);
              this.$store.commit("autoLogin", true);
            } else {
              this.$store.commit("autoLogin", false);
            }
            this.$store.commit("$_setToken", res.data.tokenValue);
            // 导航至/main  通过编程式导航跳转到后台主页
            this.$router.push("/adver1");
          }
        });
      }
    }
  };
</script>

<style lang="less" scoped>
  .footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 5px;
    color: white;
  }
  .copy {
    margin-bottom: 10px;
  }
  .login_container {
    background-color: #373d41;
    height: 100%;
  }

  .login_box {
    width: 450px;
    height: 400px;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .title {
      width: 100%;
      height: 30px;
      position: absolute;
      text-align: center;
      top: 90px;
      font-size: 20px;
      color: #373d41;
    }

    .avatar_box {
      height: 130px;
      width: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }

    .link {
      width: 100%;
      position: absolute;
      bottom: 20px;
      font-size: 14px;

      a {
        color: #888;
        text-decoration: none;
      }

      a:hover {
        color: rgb(60, 150, 250);
        text-decoration: underline;
      }

      .register {
        float: right;
        margin-right: 20px;
      }

      .forget {
        float: left;
        margin-left: 20px;
      }
    }
  }

  .login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .btns {
    text-align: center;
  }
</style>
